@import url('variables.css');

.main-nav {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    box-shadow: 0px 3px 4px -4px #000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    overflow: hidden;
    background-color: white;
}
  
  .main-nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    gap: 20px;
  }

  .main-nav-logo:visited {
    text-decoration: none;
    color: #000000;
  }
  
  .main-nav-logo > img {
    height: 70px;
  }
  
  .main-nav-logo > p {
    font-size: 2em;
    margin: 0;
    font-weight: 600;
    opacity: 100%;
  }
  
  .main-nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  
  .main-nav-menu > a {
    text-decoration: none;
    color: black;
  }

  .main-nav-menu > a:visited {
    text-decoration: none;
    color: black;
  }

  .main-nav-menu > a:hover {
    opacity: 100%;
    color: var(--color-second);
  }
  
  .main-nav-menu > a > p {
    margin: 0;
    font-size: 1.2em;
    font-weight: 300;
    opacity: 70%;
  }

  .main-nav-menu > a:nth-child(3) > div {
    max-height: 25px;
    max-width: 150px;
  }

  .main-nav-menu > a:nth-child(3) > div > img {
    max-height: 15px !important;
  }

  .main-nav-menu > a:nth-child(3) > div > p {
    font-size: 15px !important;
  }

  .main-nav-menu > a:nth-child(3) > div {
    max-height: 10px;
  }
  
  a:visited {
    color: black;
  }

  #phone-number-nav {
    max-height: 25px;
  }

  .menu-toggle-button {
    display: none;
  }

  /* Extra small devices (phones, portrait) */
@media only screen and (max-width: 600px) {
  .main-nav {
    padding: 0 10px 0 10px;
    overflow: visible;
  }

  .main-nav-logo > img {
    height: inherit;
    width: 50px;
  }

  .main-nav-logo > p {
    font-size: 1.5em;
  }
  .main-nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    gap: 10px;
    top: 100px;
    right: 0px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 3px 4px -4px #000000;
    z-index: 10;
    border-bottom: 2px solid var(--color-second);
    border-left: 2px solid var(--color-second);
  }

  .main-nav-menu.active {
    display: flex;
  }

  .main-nav-menu a {
    text-align: center;
    
  }

  .menu-toggle-button {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    padding-right: 20px;
  }
}

/* Small devices (phones, landscape) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .main-nav {
    padding: 0 10px 0 10px;
    overflow: visible;
  }
  .main-nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    gap: 10px;
    top: 100px;
    right: 0px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 3px 4px -4px #000000;
    z-index: 10;
    border-bottom: 2px solid var(--color-second);
    border-left: 2px solid var(--color-second);
  }

  .main-nav-menu.active {
    display: flex;
  }

  .main-nav-menu a {
    text-align: center;
  }

  .menu-toggle-button {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
  }
}

/* Medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 992px) {

  .main-nav {
    padding: 0 10px 0 10px;
  }
}