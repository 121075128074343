@import url('variables.css');

.info-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1;
}

.info-card > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    background-color: white;
    border: 3px solid var(--color-second);
    border-radius: 50%;
}

.info-card > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    background-color: var(--color-second);
    border-radius: 50%;
    transform: rotate(0deg);
    transition: transform 1.5s;
}

.info-card > div > div:hover {
    transform: rotate(360deg);
    transition: transform 1.5s;
    transition-duration: 1.5sec;
}

.info-card > div > div > img {
    height: 45px;
    filter: invert(100%) sepia(100%) saturate(13%) hue-rotate(209deg) brightness(105%) contrast(100%);}

.info-card > p {
    font-size: 1.3em;
    font-weight: 300;
    opacity: 100%;
    margin: 0;
    text-align: center;
    width: 150px;
}

/* Extra small devices (phones, portrait) */
@media only screen and (max-width: 600px) {
    .info-card {
        height: 200px;
      }
  }

  /* Small devices (phones, landscape) */
  @media only screen and (min-width: 600px) and (max-width: 768px) {
  .info-card {
    height: 200px;
  }
  }