@import url('variables.css');

.phone-number {
    background-color: var(--color-second);
    padding: 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    box-shadow:  4px 4px 15px -4px #0B325E;
    width: 210px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.5s ease-in-out;
}

.phone-number:hover {
    transition: background-color 0.5s ease-in-out;
    background-color: var(--color-primary);
}

.phone-number > img {
    filter: invert(94%) sepia(1%) saturate(4078%) hue-rotate(195deg) brightness(115%) contrast(100%);
    height: 20px;
}

#phone-number_p {
    margin: 0;
    color: white;
    font-size: 22px;
    font-weight: 300;
    opacity: 100%;
    transition: color 0.3s ease-in-out;
}