@import url('variables.css');

.footer {
    display: flex;
    flex-direction: column;
    background-color: #1f2c37;
    border-top: 10px solid var(--color-second);
    align-items: center;
}

.footer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    max-width: 1330px;
}

.footer > div > p {
    color: white;
    text-align: center;
}

.google-icon_a {
    display: flex;
    background-color: var(--color-third);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 10px;
    transition: background-color 500ms ease-in-out;
}

.google-icon_a:hover {
    background-color: var(--color-primary);
    transition: background-color 500ms ease-in-out;
}

.google-icon_a > img {
    height: 40px;
    width: 40px;
}

.footer-lien-ordre{
    color: white;
}

.footer-lien-ordre > a{
    color: #b59bcb;
}

.footer-article {
    max-width: 800px;
    text-align: justify;
}

/* Extra small devices (phones, portrait) */
@media only screen and (max-width: 600px) {
    .footer {
        width: 100% !important;
    }
  }


