body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

#root a {
  text-decoration: none;
  color: none;
}

#root a:visited {
  color: none;
}

/* Extra small devices (phones, portrait) */
@media only screen and (max-width: 600px) {
  .body {
    display: flex;
    width: 100% !important;
  }

  #root {
      width: 100% !important;
  }
}