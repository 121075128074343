@import url('./variables.css');

.home {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    position: relative;
}

.home-main-info {
    height: 90vh;
    min-height: 600px;
    background-image: url("../assets/flowers.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.home-main-info_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.233);
    height: 100%;
    width: 100%;
    /* border-right: 3px solid var(--color-second); */
}


.home-main-info_text > h2 {
    font-size: 2.5em;
    margin: 0;
    font-weight: 300;
    text-align: center;
    /* font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    font-style: normal; */
}

.home-main-info_text > h1 {
    font-size: 5em;
    margin: 0;
    font-weight: 500;
    text-align: center;
    /* font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    font-style: normal; */
}

.home-main-info_button {
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.233);
    height: 100%;
    width: 100%;
}

.home-main-info_button_link {
    display: flex;
    flex-direction: column;
}

.home-main-info_button_link > a {
    display: flex;
    flex-direction: column;
    gap: 200px;
}

.home-main-info_button_link > a > h2 {
    font-size: 2.5em;
    margin: 0;
    font-weight: 300;
    text-align: left;
}

.home-main-info_button_link > a > div {
    height: 70px !important;
    width: 300px !important;
    border-radius: 20px !important;
    padding: 5px !important;
}

.home-main-info_button_link > a > div > img {
    height: 30px !important;
}

.home-main-info_button_link > a > div > p {
    font-size: 2em !important;
}


.google-link {
    display: flex;
    position: relative;
    text-decoration: none;
}

.google-link:visited {
    text-decoration: none;
}

.google-link-slide {
    display: flex;
    position: relative;
    width: 450px;
    height: 80px;
    overflow: hidden;
}

.google-link-slide_info {
    display: flex;
    align-items: center;
    width: 450px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.741);
    transition: transform 500ms ease-in-out;
    border: 4px solid var(--color-third);
    transition: border 500ms ease-in-out;
}

.google-link-slide_info > p {
    padding-left: 90px;
    font-size: 2em;
    margin: 0;
    font-weight: 400;
    color: var(--color-third);
    transition: color 500ms ease-in-out;
}

.google-link-slide_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-third);
    border-radius: 50%;
    height: 60px;
    width:60px;
    padding: 10px;
    transition: background-color 500ms ease-in-out;
    position: absolute;
}

.google-link:hover .google-link-slide_icon {
    background-color: var(--color-primary);
    transition: background-color 500ms ease-in-out;
}

.google-link:hover .google-link-slide_info {
    transition: border 500ms ease-in-out;
    border: 4px solid var(--color-primary);
}

.google-link:hover .google-link-slide_info > p {
    color: var(--color-third);
    transition: color 500ms ease-in-out;
}

.google-link-slide_icon > img {
    height: 50px;
    width: 50px;
}

.home-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 50px 0;
}

.home-info > div {
    margin: 0 100px 0 100px;
}

img { max-width: 1300px;}

.home-info-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1330px;
}

.home-info-display h1 {
    font-weight: 300;
    opacity: 70%;
    text-align: center;
    margin: 10px 0 10px 0;
}

.home-info-display p {
    font-weight: 400;
    font-size: 1.1em;
    font-weight: 400;
    opacity: 70%;
}

.home-info-display > div {
    display: flex;
    justify-content: center;
    max-width: 1330px;
    height: 500px;
    overflow: hidden;
}

.home-info-display > div > div {
    flex: 1;
}

.home-info-display > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 10%;
}

.home-info-display-line-first > div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-info-display_card {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
}

.home-info-display_card > div {
    display: flex;
    flex: 1;
}

.home-info-display-line-second > div:nth-child(1) > div {
    padding: 0 10px 10px 10px;
}

.home-info-display-line-second > div:nth-child(1) > div p {
    text-align: justify;
}

.home-info-display-line-second > div:nth-child(3) > div {
    padding: 0 10px 10px 10px;
}

.home-info-display-line-second > div:nth-child(3) > div p {
    text-align: justify;
}

.home-info-display-line-third > div:nth-child(1) {
    flex: 1;
}

.home-info-display-line-third > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#purple-bar {
    height: 8px;
    background-color: var(--color-second);
}

.home-info-display-line-third_text {
    padding: 0 10px 10px 10px;
}

.home-info-display-line-third_text p {
    text-align: justify;
}

.home-info-display-line-third  > div:nth-child(2) {
    flex: 2;
}

.home-info-display-line-third > div:nth-child(2) > iframe {
    width: 100%;
    height: 100%;
}

.check-green {
    width: 18px;
    /* filter: invert(26%) sepia(64%) saturate(6827%) hue-rotate(114deg) brightness(92%) contrast(107%); */
    filter: invert(14%) sepia(26%) saturate(5711%) hue-rotate(268deg) brightness(104%) contrast(129%);
}

#stetho {
    /* object-position: -550px 0px; */
    object-fit: cover;
}

.check-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.check-list > div {
    display: flex;
    gap: 10px;
}

.check-list > div > p {
    margin: 0; 
}

/* Extra small devices (phones, portrait) */
@media only screen and (max-width: 600px) {
    .home-main-info {
        flex-direction: column;
        min-height: 1000px !important;
    }

    .home-main-info_text {
        align-items: center;
        width: 100%;
        padding: inherit !important;
    }

    .home-main-info_text > h2 {
        font-size: 1.8em !important;
    }

    .home-main-info_text > h1 {
        text-align: center;
        font-size: 3em !important;
    }

    .google-link {
        width: 300px;
    }

    .home-info {
        padding: 0;
        border-top: 8px solid var(--color-second);
    }

    .home-info-display {
        margin: 0 !important;
    }

    .home-info-display-line-first {
        flex-direction: column-reverse;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-first > div {
        height: 350px !important;
        flex: inherit !important;
    }

    .home-info-display-line-first > div:nth-child(2) {
        height: 500px !important;
    }

    .home-info-display_card {
        flex-direction: column;
        gap: 0;
        align-items: center;
        justify-content: center;
    }

    .home-info-display-line-second {
        flex-direction: column;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-second > div {
        height: 500px !important;
        flex: inherit !important;
    }

    .home-info-display-line-third {
        flex-direction: column;
        height: 700px !important;
        gap: 30px;
    }
  }
  
  /* Small devices (phones, landscape) */
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    .home-main-info {
        flex-direction: column;
    }

    .home-main-info_text {
        align-items: center;
    }

    .home-main-info_text > h2 {
        font-size: 1.8em !important;
    }

    .home-main-info_text > h1 {
        text-align: center;
        font-size: 3em !important;
    }

    .home-info {
        padding: 0;
        border-top: 8px solid var(--color-second);
    }

    .home-info-display {
        margin: 0 !important;
    }

    .home-info-display-line-first {
        flex-direction: column-reverse;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-first > div {
        height: 350px !important;
        flex: inherit !important;
    }

    .home-info-display_card {
        flex-direction: row;
        gap: 0;
        align-items: center;
        justify-content: center;
    }

    .home-info-display-line-second {
        flex-direction: column;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-second > div {
        height: 350px !important;
        flex: inherit !important;
    }

    .home-info-display-line-third {
        flex-direction: column;
        height: 700px !important;
        gap: 30px;
    }
  }
  
  /* Medium devices (tablets) */
  @media only screen and (min-width: 768px) and (max-width: 992px) {

    .home-main-info {
        flex-direction: column;
    }

    .home-main-info_text {
        align-items: center;
    }

    .home-main-info_text > h1 {
        text-align: center;
    }

    .home-info {
        padding: 0;
        border-top: 8px solid var(--color-second);
    }

    .home-info-display {
        margin: 0 !important;
    }

    .home-info-display-line-first {
        flex-direction: column-reverse;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-first > div {
        height: 350px !important;
        flex: inherit !important;
    }

    .home-info-display_card {
        flex-direction: row;
        gap: 0;
        align-items: center;
    }

    .home-info-display-line-second {
        flex-direction: column;
        height: inherit !important;
        gap: 15px;
    }

    .home-info-display-line-second > div {
        height: 350px !important;
        flex: inherit !important;
    }

    .home-info-display-line-third {
        flex-direction: column;
        height: 700px !important;
        gap: 30px;
    }
  }
  
  /* Large devices (desktops) */
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .home-main-info {
        flex-direction: column;
    }

    .home-main-info_text {
        align-items: center;
    }

    .home-main-info_text > h1 {
        text-align: center;
    }

    .home-info-display {
        margin: 0 !important;
    }
  }
